/* Customize the label (the container) */
.calendar-legend {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-right: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .calendar-legend input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .calendar-legend input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .calendar-legend .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .online {
    background-color: #A0F18C;
    border: solid #A0F18C;
  }

  .reservation-you {
    background-color: #ffc627;
    border: solid #ffc627;
  }

  .reservation {
    background-color: #57cfb6;
    border: solid #57cfb6;

  }

  .maintenance {
    background-color: #c0c0c0;
    border: solid #c0c0c0;

  }

  .unchecked {
    background-color: rgba(255, 255, 255, 0) !important;
  }