.umui-calendar-event {
    display: flex;
    /* align-items: center; */
    padding: 5px 5px 5px 2px;
    border-top-color: #e0e0e0;
    border-top-style: solid;
    border-top-width: thin;
}

.event-details {
    display: flex;
    align-items: center;
    width: 80%;
}

.event-times {
    min-width: 150px
}

.umui-actions {
    display: flex;
    margin-left: auto;
}

.umui-reserve-divider {
    background-color: #57cfb6 !important;
    width: 4px !important;
}

.umui-user-reserve-divider {
    background-color: #ffc627 !important;
    width: 4px !important;
}
.umui-maintenance-divider {
    background-color: #c0c0c0 !important;
    width: 4px !important;
}

.umui-online-divider {
    background-color: #A0F18C !important;
    width: 4px !important;
}