/*****************************************************************************

2020 @ Honeywell International.
This software and all information and expression are the property of
Honeywell International, Inc., are Honeywell Confidential & Proprietary,
contain trade secrets and may not, in whole or in part, be licensed,
used, duplicated, disclosed, or reproduced for any purpose without prior
written permission of Honeywell International Inc.
All Rights Reserved.

*****************************************************************************/
.HQSAdmin {
    display: flex;
    flex-flow: column;
    padding: 0 10px 0 10px;
}

.hqs-admin-view {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.org-table-wrapper {
    margin: auto;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 2.5em;
    display: flex;
    flex-flow: column;
    width: 65%;
    text-align: left;
}

.org-table-wrapper a {
    text-align: right;
}

.ui.button.inline {
    margin: 5px;
}

.org-admins-header {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

/* This allows us to put the title and the button on the
same line as the icon */
.accordion.ui .title {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
}

.org-admin-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.hqs-admin-card {
    margin: 0 5px 0 5px;
    height: 700px;
    width: 50%;
    background-color: #fff;
    padding: 1em;
    border-radius: 7px;
}

.hqs-admin-subcard {
    margin-right: auto;
    height: 80px;

    background-color: #fff;
    border-radius: 7px;
}

.hqs-admin-users-card,
.hqs-admin-jobs-card,
.hqs-admin-fq-card {
    margin: 0;
    width: 100%;
    background-color: #fff;
    padding: 1em;
    border-radius: 7px;
    height: 700px;
}

.hqs-admin-fq-metrics {
    display: flex;
    flex-flow: row;
}

.hqs-admin-jobs-card .ui.date-picker-input .calendar-overlay {
    right: 0px;
    bottom: 0px;
}
.hqs-admin-jobs-card .ui.date-picker-input .calendar-overlay .header {
    background-color: #fff;
}
.hqs-admin-jobs-card .p-datatable.jobs-data-table {
    min-height: 500px;
}
.hqs-admin-jobs-card .p-datatable.jobs-data-table .p-datatable-header .below-text {
    display: none;
}

.hqs-admin-jobs-card
    .p-datatable.jobs-data-table
    .p-datatable-header
    .filter-bar
    .menu-items
    .filter-panel.show {
    max-height: 400px;
}

.hqs-admin-centered {
    top: 50%;
    left: 25%;
    position: relative;
}

/* Jobs table CSS*/
.jobs-table {
    max-width: 500px;
    max-height: 500px;
}

.hqs-fq-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.hqs-fq-values {
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Bold';
    font-size: 16px;
    font-weight: bold;
    height: 36px;
    letter-spacing: 0px;
    line-height: 36px;
    width: auto;
    display: inline-block;
    white-space: nowrap;
    margin-right: 10px;
}

.hqs-fq-header {
    color: rgb(112, 112, 112);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: -7px 5px 0 0;
}

.hqs-fq-info {
    display: flex;
    flex-direction: row;
}

.hqs-fq-subcard {
    margin-right: 20px;
    height: 55px;
    background-color: #fff;
    border-radius: 7px;
}

.hqs-admin-fq-data {
    height: 590px;
    overflow: auto !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
}