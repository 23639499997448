/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

 .table-summary td, .table th {
    padding: 0px 10px 10px 0px !important;
}



.table-summary tr {
    vertical-align: top;
    
}


/* allow form to be bigger on wider/taller screen */
@media only screen and (min-height: 1200px) {

    .any-plan-modal-form {
        max-height:1200px  !important;
    }

}