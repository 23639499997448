.umui-tag-record {
    display: flex;
    /* align-items: center; */
    padding: 5px 5px 5px 2px;
    min-width: 100px;
    border-top-color: #e0e0e0;
    border-top-style: solid;
    border-top-width: thin;
}

.umui-tag-header {

    color: rgb(112, 112, 112);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 10px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 9px 5px 0 13px;
}

.umui-tag-record-value {
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Bold';
    font-size: 13px;
    height: 36px;
    min-width: 50px;
    letter-spacing: 0px;
    line-height: 36px;
    width: auto;
    display: inline-block;
    white-space: nowrap;
    margin: 2px 18px 0 1px;
    min-width: 100px;
}

