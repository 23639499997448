/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/
* {
    box-sizing: border-box;
}

.main-wrapper {
    margin: auto;
    font-family: 'Honeywell Sans Web';
}

.App {
    margin: auto;
    font-family: 'Honeywell Sans Web';
}

.App-wrapper {
    overflow: hidden;
    display: flex;
}

.View-buttons {
    float: left;
    width: 200px;
    border: 1px solid black;
}

.View-button {
    display: block;
    width: 100%;
    font-size: 28px;
}

.View-header {
    font-family: 'Honeywell Sans Web', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 650;
    height: 36px;
    letter-spacing: 0px;
    line-height: 36px;
    margin: 0.5em 0 0 0;
    padding-left: 5px;
}

.View-wrapper {
    flex-grow: 1;
    justify-content: center;
}

.main-logo {
    width: 10.5rem !important;
    padding-left: 0.5rem !important;
    background-size: auto !important;
}

.item.logo-item {
    width: 400px !important;
    padding-left: 0 !important;
    justify-content: right !important;
}

.hqs-umui-card {
    margin: 0 5px 0 5px;
    height: 700px;
    background-color: #fff;
    padding: 1em;
    border-radius: 7px;
}

.ui.loader-wrap.hqs-umui-loader-wrap {
    z-index: 20;
}

.ui.modal.hqs-umui-delete-modal {
    border-style: solid !important;
    border-width: 2px 2px 2px 6px !important;
    border-color: #be271c !important;
}

.ui.modal.hqs-umui-information-modal {
    border-style: solid !important;
    border-width: 2px 2px 2px 6px !important;
    border-color: #1792e5 !important;
}

/* overriding as it creates conflict for Forge Modal */
.modal {
    height: auto !important;
}

.button {
    margin: 5px !important;
    border-radius: 30px !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.ui.modal.hqs-umui-modal {
    padding: 24px;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.ui.modal.hqs-umui-modal > .content {
    padding: 0px 8px 0px 40px;
    display: flex;
    flex-direction: column;
}

.hqs-umui-modal-header {
    font-family: 'Honeywell Sans Web';
    font-size: 16px;
    font-weight: 800;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
}

input[type='checkbox'] {
    height: 1rem !important;
    width: 1rem !important;
    /* margin-left: 9px; */
}

.hqs-umui-card-header {
    margin: 0 0 1em 0;
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;   
}

.hqs-umui-card-header-gray {
    margin: 0 0 1em 0;
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;
    background-color: #95979929;
}

.hqs-umui-card-subheader {
    color: rgb(112, 112, 112);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 1em 0 0.5em 0;
}

.hqs-form-button-wrapper {
    margin-top: auto;
    text-align: right;
    position: relative;
}

.hqs-form-group {
    display: flex;
    flex-flow: column nowrap;
}

.hqs-form-group > * {
    padding: 0px;
    padding-top: 12px;
}
.hqs-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.form-check {
    margin-right: 1.5em !important;
}

.ellipsis-field {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    width: 167px !important;
}
.ellipsis-field:hover {
    overflow: visible;
}

.ui.scuf-grid {
    margin: 0 !important;
}

.hqs-custom-button {
    margin: 0px !important;
}

.hqs-custom-button-error {
    background-color: red !important;
    border: .0625rem solid red !important;
}

.ui.menu:not(.vertical) .right.menu {
    margin-right: 5px !important;
}

.cell-data-wrap {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

/* fullcalendar overrides */
.fc-content {
    padding: 3px 3px 3px 4px !important;
    text-align: center !important;
    color: rgb(48, 48, 48) !important;
    font-family: HoneywellSans-Bold !important;
    font-size: 12px !important;
    height: 29px !important;
    cursor: pointer;
}

.fc-time {
    display: none !important;
}

.fc-axis.fc-time.fc-widget-content {
    display: table-cell !important;
}

.fc-event {
    border-radius: 10px !important;
    border: 0px !important;
}

.fc-left > div {
    display: flex !important;
}

.fc-prev-button,
.fc-next-button {
    background-color: white !important;
    border: 0px !important;
    margin-right: 28px !important;
    margin-left: 28px !important;
}

.fc-icon-chevron-left:before {
    font-family: 'fcicons' !important;
    color: #303030;
}

.fc-icon-chevron-right:before {
    font-family: 'fcicons' !important;
    color: #303030;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button {
    background-color: white !important;
    color: #303030 !important;
    border: 1px solid #c0c0c0 !important;
}

.scuf-col.col-12 {
    padding-bottom: 0px;
}

.fc-sat,
.fc-sun {
    background-color: #f5f5f5 !important;
}

.user-events-list {
    overflow: auto;
    height: 140px;
}

/* overrides for forgot password forms*/
.Button__button___vS7Mv {
    border: 0.0625rem solid #1274b7 !important;
    background: linear-gradient(0deg, rgb(0, 114, 194) 0%, rgb(23, 146, 229) 100%);
    color: #fff !important;
    text-transform: uppercase !important;
    font-family: 'Honeywell Sans Web', Arial, Helvetica, sans-serif !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    margin: 5px !important;
    border-radius: 30px !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
    min-width: auto !important;
}

.Input__inputLabel___3VF0S {
    font-family: 'Honeywell Sans Web', Arial, Helvetica, sans-serif !important;
    color: #303030 !important;
    letter-spacing: 0px !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    color: #303030 !important;
    font-weight: 700 !important;
}

.Input__input___3e_bf {
    height: 2.5rem !important;
    border-radius: 0 !important;
}

.Input__input___3e_bf:focus {
    border: solid 1px #1274b7 !important;
    outline: 0px !important;
}

.Anchor__a___1_Iz8 {
    color: rgb(23, 146, 229) !important;
    font-family: HoneywellSans-Bold !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-top: 10px !important;
}

.Form__formSection___1PPvW {
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%) !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3), 0px 4px 12px 0px rgba(0, 0, 0, 0.1) !important;
}

/* overrides for loading icon*/
.p-datatable-loading-icon.pi-spin.h-icon.global.refresh.loading {
    color: #1274b7 !important;
    font-size: 2rem !important;
}

/*hover for menu items*/
.ui.page-header .page-header-menu.ui.menu .item:not(.logo-item):not(.menu-btn):hover {
    background: #e0e0e0 !important;
}

/*overrides for dropdowns withing a small modal*/
#short-select .visible.menu.transition {
    max-height: 141px;
}

/* override for date selector in safari */
.calendar-overlay-wrap{
    -webkit-transform:translateZ(5px) !important;
    position: relative !important;

}

.filter-panel.show{
    -webkit-transform:translateZ(5px) !important;
}

/*custom table overrides*/
.hqs-custom-table-row{
    padding: 2px 1px 1px 2px;
}

.hqs-custom-table-header {
    padding: 5px !important;
    white-space: nowrap !important;
    font-family: "Honeywell Sans Web",Arial,Helvetica,sans-serif !important;
    font-weight: 600 !important;
    color: #606060 !important;
    line-height: 1.25rem !important;
    font-size: .75rem !important;
    vertical-align: middle !important;
    position: sticky;
}

.hqs-custom-table-option {
    padding: 9px 1px 1px 10px;
}

.react-bootstrap-table th {
    position: sticky;
    top: 1px;
    background-color: #fff;
  }

.hqs-custom-table-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-bootstrap-table th .order > .dropdown > .caret {
    margin: 10px 0 10px 5px !important;
    color: #cccccc !important;
}

.react-bootstrap-table th .order > .dropup > .caret {
    margin: 10px 0 10px 5px !important;
    color: #cccccc !important;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.caret-active {
    color:rgb(0, 114, 194)
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

.table td, .table th {
    padding: 5px 7px 5px 7px !important;
}

.table th {
    text-align: center;
}

.hqs-custom-font{
    font-family: "Honeywell Sans Web",Arial,Helvetica,sans-serif !important;
}
/* Tab menu overrides */
div.ui.pointing.secondary.ui.scuf-tab.menu {
    padding-left: 5px;
}

.ui.page-header .page-header-menu.ui.menu .item:not(.search-item) {
    justify-content: left !important;
    padding-left: 6px !important;

}

.hqs-admin-jobs-data {
    height: 590px;
    overflow: auto !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
}

/* custom menu item */
.hqs-menu-item {
    cursor: pointer;
    align-content: center;
    text-decoration: none;
    font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #303030;
    font-size: .875rem;
    line-height: 1.375rem;
    font-weight: 500;
    border: none;
    position: relative;
    border-radius: 0;
    padding: 12px 24px 12px 24px;
    min-width: 2.5rem;
}

.hqs-menu-item:hover {
    background: #e0e0e0 !important;
    color: #303030;

}

.hqs-menu-active-item {
    font-weight: 700;
    background: #e0e0e0 !important;
    border: 0;

}

.hqs-app-version {
    align-content: center;
    text-decoration: none;
    font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    color: #707070;
    font-size: .875rem;
    line-height: 1.375rem;
    font-weight: 500;
    border: none;
    position: relative;
    border-radius: 0;
    padding: 12px 24px 12px 24px;
    min-width: 2.5rem;
}

/* Actions Select */
.hqs-custom-actions-select-option {
    /* color: rgb(112, 112, 112); */
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    /* text-transform: uppercase; */
    margin: 1em 0 0.5em 0;
}

.hqs-custom-dropdown {
    padding-top: 2px !important;
}

.ui.search.dropdown .menu {
    max-height: 10.029rem !important;
}

/* reduce padding on large screen */
@media only screen and (min-width: 1645px) {

    .hqs-menu-item,
    .hqs-app-version {
        padding: 12px 10px;
    }


}

.hqs-umui-quotas-list {
    border: 1px solid #1274B7
}

.hqs-notification-banner {
    vertical-align: middle;
    text-align:center;
    line-height: 40px;
    background-color: #A5E5D7;
}