/*****************************************************************************

2021 @ Honeywell International.
This software and all information and expression are the property of
Honeywell International, Inc., are Honeywell Confidential & Proprietary,
contain trade secrets and may not, in whole or in part, be licensed,
used, duplicated, disclosed, or reproduced for any purpose without prior
written permission of Honeywell International Inc.
All Rights Reserved.

*****************************************************************************/
.hqs-user {

    padding: 0 10px 0 10px;
}

.user-view-dashboard {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
}

.user-view-dashboard-panel {
    border: 1px solid black;
    height: 300px;
    margin: 1%;
}

.hqs-terms-view {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.hqs-terms-card {
    display: flex;
    margin: 0 5px 0 5px;
    flex-direction: column;
    height: 700px;
    background-color: #fff;
    padding: 14px 14px 14px 14px;
    border-radius: 7px;
}

.medium-card {
width: 25%;
}

.hqs-custom-card {
    padding: 14px 14px 14px 14px;
}

.large-card {
    width: 715px;
    padding: 1em;
}

.editor-card {
    width: 75%;
    padding: 1em;
    padding-top: 1.2em;
}

.tree-view {
    margin-top: 5px;
}
.node-link {
    cursor: pointer;
    color: #000;
}
.node-link:hover {
    text-decoration: none;
    color: #7f8c8d;
}

.hqs-customer-info {
    display: flex;
    flex-direction: row;
    margin-bottom: -20px;
    margin-top: 15px
}

.hqs-admin-subcard {
    margin-right: auto;
    height: 80px;

    background-color: #fff;
    border-radius: 7px;
}

.hqs-terms-card {
    margin: 0 5px 0 5px;
    height: 700px;
    width: 100%;
    background-color: #fff;
    padding: 1em;
    border-radius: 7px;
}


.hqs-settings-info {
    text-align: center;
}

.hqs-settings-header {
    font-size: 16px;
    text-align: center;
}

.hqs-settings-modal {
    padding: 50px !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: auto !important;
}

/* override the direction of the accordion dropdown*/
.accordion.ui .title {
    flex-direction: unset !important;
}



/* Forge Overrides */
ui.scuf-grid,
.ui.scuf-grid.media-xs {
    margin: 0 !important;
}

.tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.6rem 0;
  }
  
  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
