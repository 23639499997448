/*****************************************************************************

2021 @ Honeywell International.
This software and all information and expression are the property of
Honeywell International, Inc., are Honeywell Confidential & Proprietary,
contain trade secrets and may not, in whole or in part, be licensed,
used, duplicated, disclosed, or reproduced for any purpose without prior
written permission of Honeywell International Inc.
All Rights Reserved.

*****************************************************************************/

/* shorten the page size picker */
.hqs-page-size-dropdown {
    max-width: 25% !important;
}

/* shorten the page size picker */
.hqs-page-size-dropdown > .ui.scuf-dropdown-wrapper > .ui.selection.dropdown{
    min-width: 50% !important;
    max-width: 50% !important;
}

/* remove the min-height underneath the org picker */
.hqs-table-dropdown-option > .ui.scuf-dropdown-wrapper > .below-text{
    min-height: 0rem !important;
}

/* remove the min-height underneath the date picker */
.hqs-table-dropdown-option > .ui.date-picker-input > .overlay-position-wrap > .datetime-input-wrap > .picker-input > .below-text{
    min-height: 0rem !important;
}