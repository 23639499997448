.custom-sign-up {
    /* setup background img */
    background-image: url("./hqs-inverse-login.png") !important;
    background-size: cover;
    background-position: center; 
    background-repeat:no-repeat;
    height: 100vh;
    width: 100%;
    
    /* center sign in form */
    display: flex;
    flex-flow: center;
    justify-content: center;
    align-items: center;
}

.sign-up-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    height: 540px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.sign-up-complete-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.form-completed {
    /* center form */
    display: flex;
    flex-flow: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.form-completed-p {
    padding-top: 90px;
}

.custom-link {
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Honeywell Sans Web",Arial,Helvetica,sans-serif;
    font-weight: 600;
    font-size: .875rem;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
    border: .0625rem solid #1274B7;
    background-color: #1274B7;
    margin: 5px!important;
    border-radius: 30px!important;
    padding-left: 1em!important;
    padding-right: 1em!important;
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}
a:visited.custom-link, a:active.custom-link, a:hover.custom-link {
    color: #fff;
}

.agreement-confirm{
    margin-top: 5px;
    text-align: center;
    margin-bottom: 5px
}

.software-confirm{
    margin-top: 5px;
    text-align: left;
    margin-bottom: 5px
}

