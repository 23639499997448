/*****************************************************************************

2020 @ Honeywell International.
This software and all information and expression are the property of
Honeywell International, Inc., are Honeywell Confidential & Proprietary,
contain trade secrets and may not, in whole or in part, be licensed,
used, duplicated, disclosed, or reproduced for any purpose without prior
written permission of Honeywell International Inc.
All Rights Reserved.

*****************************************************************************/
.hqs-org-admin {
    padding: 0 10px 0 10px;

}
.user-table-wrapper {
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-flow: column;
}

table.user-table > tr > td,
table.user-table > tr > th {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hqs-org-view {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.hqs-org-admin-card {
    display: flex;
    margin: 0;
    flex-direction: column;
    height: 700px;
    background-color: #fff;
    padding: 1em 1em 1em 0.5em;
    border-radius: 7px;
}

.medium-card {
    width: 25%;
}

.large-card {
    margin: 0 5px 0 5px;
    width: 50%;
    padding: 1em;
}

/* allow's the BadedIcon's color property to override the default white value on the BatchRequestsFilter*/
.ui.forge-datatable-wrap .p-datatable .p-datatable-header {
    color: unset !important;
}