/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

.custom-sign-in {
    /* setup background img */
    background-image: url("./hqs-inverse-login.png");
    background-size: cover;
    background-position: center; 
    background-repeat:no-repeat;
    height: 100vh;
    width: 100%;
    
    /* center sign in form */
    display: flex;
    flex-flow: center;
    justify-content: center;
    align-items: center;
}

.sign-in-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    height: 600px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.terms-and-conditions-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

.mfa-sign-in-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    height: 550px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}


.forgot-password-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 30px 40px 40px 40px;
    width: 540px;
    min-height: 530px;
    background: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, rgba(247, 247, 247, 0.8) 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3),
        0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}


.form-header {
    margin-top: 40px;
    width: 100%;
    padding-left: 10px;
}

.credentials {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px
}

.forgot-password {
    color: rgb(23, 146, 229);
    font-family: HoneywellSans-Bold;
    font-size: 14px;
    font-weight: bold; 
    margin-top: 10px;
}

.forgot-password-link:hover {
    cursor: pointer;
    justify-content: left;
}

.resend-code {
    font-family: HoneywellSans-Bold;
    font-size: 14px;
    font-weight: bold; 
    margin-top: 10px;
}

.resend-code-link {
    color: rgb(23, 146, 229) !important;
    font-size: 14px;
    font-weight: bold; 
    margin-top: 10px;
}

.resend-code-link:hover {
    cursor: pointer;
    justify-content: left;
}

.cred-label {
    color: rgb(255, 255, 255) !important;
    font-size: 16px;
    font-family: HoneywellSans-Bold;
    font-weight: bold; 
}

.password, .username, .submit-creds, .disclaimer {
    margin-top: 30px;
}

.federated {
    text-align: center;
    margin-top: 5px;
}

.new-password {
    margin-top: 20px;
}

.change-password {
    margin-top: 5px;
}

.password-validations {
    margin-top: 15px;
    font-weight: bold; 
}

.mfa-confirm{
    margin-top: 30px;
    text-align: center;
}

.mfa-steps{
    text-align: left
}

.verification-code {
    margin-top: 10px;
}

.verify-code {
    margin-top: 5px;
    text-align: center;
}


.center-text {
    text-align: center
}

.disclaimer {
    font-family: HoneywellSans-Bold;
    font-size: 15px;
}

.terms-disclaimer {
    font-family: HoneywellSans-Bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.input-button {
    background: linear-gradient(0deg, rgb(0, 114, 194) 0%, rgb(23, 146, 229) 100%);
    margin-top: 40px;
    font-family: 'Honeywell Sans Web';
}

.input-form {
    border-radius: 4px !important;
    width: 100%;
    height: 56px;
    width: 430px;
}

.logo {
    width: 100%;
    height: auto;
}

.ms-logo {
    height: auto;
    cursor:pointer
}