.hqs-plan-container {
    display: flex;
    flex-direction: row;
}

.hqs-customer-info {
    display: flex;
    flex-direction: row;
    margin-bottom: -20px;
    margin-top: 15px
}

.hqs-customer-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
}

.hqs-plan-values {
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Bold';
    font-size: 16px;
    font-weight: bold;
    height: 36px;
    letter-spacing: 0px;
    line-height: 36px;
    width: auto;
    display: inline-block;
    white-space: nowrap;
    margin-right: 10px;
}

.hqs-customer-values {
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Bold';
    font-size: 16px;
    font-weight: bold;
    height: 36px;
    letter-spacing: 0px;
    line-height: 36px;
    width: auto;
    display: inline-block;
    white-space: nowrap;
    margin-right: 10px;
}

.hqs-blue-icon {
    color: rgb(0, 114, 194) !important;
    font-family: 'Honeywell Sans Web', 'Medium' !important;
    height: 24px !important;
    letter-spacing: 0px !important;
    line-height: 24px !important;
    margin: 8px 20px 0 -5px;
    font-size: 0.8rem !important;
}

.hqs-customer-header {
    color: rgb(112, 112, 112);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 9px 5px 0 0;
}
