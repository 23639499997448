.umui-quota-record {
    display: flex;
    /* align-items: center; */
    padding: 5px 5px 5px 2px;
    border-top-color: #e0e0e0;
    border-top-style: solid;
    border-top-width: thin;
}

.umui-quota-header {

    color: rgb(112, 112, 112);
    font-family: 'Honeywell Sans Web', 'Medium';
    font-size: 10px;
    font-weight: 600;
    height: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 9px 5px 0 13px;
}

.umui-quota-icons {
    padding-top: 4px;
}

.umui-quota-record-value {
    color: rgb(48, 48, 48);
    font-family: 'Honeywell Sans Web', 'Bold';
    font-size: 13px;
    height: 36px;
    letter-spacing: 0px;
    line-height: 36px;
    width: auto;
    display: inline-block;
    white-space: nowrap;
    margin: 2px 18px 0 1px;
}

.event-details {
    display: flex;
    align-items: center;
    width: 80%;
}

.umui-actions {
    display: flex;
    margin-left: auto;
}

.umui-reserve-divider {
    background-color: #57cfb6 !important;
    width: 4px !important;
}

.umui-user-reserve-divider {
    background-color: #ffc627 !important;
    width: 4px !important;
}
.umui-maintenance-divider {
    background-color: #c0c0c0 !important;
    width: 4px !important;
}

.umui-online-divider {
    background-color: #A0F18C !important;
    width: 4px !important;
}